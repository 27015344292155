import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersOrderFindByIdForClientRequestSchema } from './request';
import { OrdersOrderFindByIdForClientResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersOrderFindByIdForClientRequestSchema extends ContractData {
*     id?: number;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersOrderFindByIdForClientResponseSchema extends ContractData {
*     order: Order;
* }
*
* export interface Order {
*     additionalInfo:   OrderAdditionalInfo;
*     billingAddress:   BillingAddress;
*     createdAt:        Date;
*     deliveryPrice:    number;
*     deliveryStatus:   DeliveryStatus;
*     externalNumber:   string;
*     id:               number;
*     paymentStatus:    PaymentStatus;
*     productionPrice:  number;
*     productionStatus: ProductionStatus;
*     shipments:        Shipment[];
*     shippingAddress:  ShippingAddress;
*     shipstationId:    string;
*     status:           OrderStatus;
*     validationStatus: ValidationStatus;
*     history:          History[];
*     products:         Product[];
*     store:            Store;
* }
*
* export interface OrderAdditionalInfo {
*     cancellationReason?:   string;
*     carrierCode:           string;
*     createDate:            string;
*     customerId:            number;
*     customerNotes:         string;
*     customerUsername:      string;
*     email:                 string;
*     gift:                  boolean;
*     giftMessage:           string;
*     internalNotes:         string;
*     invalidAddressReason?: InvalidAddressReason;
*     modifyDate:            string;
*     orderDate:             string;
*     paymentDate:           string;
*     serviceCode:           string;
*     shippingNumber:        string;
* }
*
* export type InvalidAddressReason = "state" | "postType";
*
* export interface BillingAddress {
*     addressVerified?: AddressVerified;
*     city:             string;
*     company?:         string;
*     country:          string;
*     name:             string;
*     phone?:           string;
*     postalCode:       string;
*     residential:      boolean;
*     state:            string;
*     street1:          string;
*     street2?:         string;
*     street3?:         string;
* }
*
* export type AddressVerified = "Address not yet validated" | "Address validated successfully" | "Address validation warning" | "Address validation failed";
*
* export type DeliveryStatus = "pending" | "shipped" | "delivered";
*
* export interface History {
*     date:   Date;
*     status: HistoryStatus;
* }
*
* export type HistoryStatus = "validation_placed" | "validation_invalid-sku" | "validation_invalid-shipping-address" | "validation_waiting-customizations" | "validation_completed" | "validation_ignored" | "payment_pending" | "payment_processing" | "payment_failed" | "payment_requires-action" | "payment_paid" | "production_pending" | "production_in-production" | "production_completed" | "production_packaged" | "delivery_pending" | "delivery_shipped" | "delivery_delivered";
*
* export type PaymentStatus = "pending" | "processing" | "failed" | "paid" | "requires-action";
*
* export type ProductionStatus = "pending" | "in-production" | "completed" | "packaged";
*
* export interface Product {
*     additionalInfo:  ProductAdditionalInfo;
*     deliveryPrice:   number;
*     designRules:     DesignRules;
*     designs:         Design[];
*     id:              number;
*     name:            string;
*     personalization: Personalization[];
*     picture:         string;
*     productId:       number;
*     productionPrice: number;
*     quantity:        number;
*     sku:             string;
* }
*
* export interface ProductAdditionalInfo {
*     deliveryPricePaidBy?: number;
* }
*
* export interface DesignRules {
*     fileRules?: FileRules;
*     textRules?: TextRules;
*     type:       Type;
* }
*
* export interface FileRules {
*     allowedExtensions: string[];
*     height?:           number;
*     units?:            Units;
*     width?:            number;
* }
*
* export type Units = "px" | "in";
*
* export interface TextRules {
*     notice: string;
*     regexp: string;
* }
*
* export type Type = "file" | "text";
*
* export interface Design {
*     type:  Type;
*     value: string;
* }
*
* export interface Personalization {
*     key:   string;
*     value: string;
* }
*
* export interface Shipment {
*     carrierCode:    string;
*     serviceCode:    string;
*     shipDate:       string;
*     shipmentId:     number;
*     status:         ShipmentStatus;
*     trackingNumber: string;
*     trackingUrl:    string;
* }
*
* export type ShipmentStatus = "shipped" | "canceled";
*
* export interface ShippingAddress {
*     addressVerified?: AddressVerified;
*     city:             string;
*     company?:         string;
*     country:          string;
*     name:             string;
*     phone?:           string;
*     postalCode:       string;
*     residential:      boolean;
*     state:            string;
*     street1:          string;
*     street2?:         string;
*     street3?:         string;
* }
*
* export type OrderStatus = "validation" | "payment" | "production" | "delivery" | "canceled" | "ignored";
*
* export interface Store {
*     id:        number;
*     source:    Source;
*     storeId:   number;
*     storeName: string;
*     userId:    string;
*     userName?: string;
* }
*
* export type Source = "etsy" | "shipstation" | "manual";
*
* export type ValidationStatus = "placed" | "invalid-sku" | "invalid-shipping-address" | "waiting-customizations" | "completed" | "ignored";
*
* ```
*/
export class OrdersOrderFindByIdForClientRouteRpc extends BaseRouteRpc {
  static method = 'orders-order-find-by-id-for-client';

  static request: OrdersOrderFindByIdForClientRequestSchema;
  static response: OrdersOrderFindByIdForClientResponseSchema;

  static role = RoleType.unverified;
}
