import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindAllForAdminByUserRequestSchema } from './request';
import { OrdersAllowedStoresFindAllForAdminByUserResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindAllForAdminByUserRequestSchema extends ContractData {
*     userId: string;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindAllForAdminByUserResponseSchema extends ContractData {
*     stores: Store[];
* }
*
* export interface Store {
*     id:              number;
*     source:          Source;
*     storeId:         number;
*     storeName:       string;
*     userId:          string;
*     ordersTotal:     number;
*     paidOrdersTotal: number;
* }
*
* export type Source = "etsy" | "shipstation" | "manual";
*
* ```
*/
export class OrdersAllowedStoresFindAllForAdminByUserRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-all-for-admin-by-user';

  static request: OrdersAllowedStoresFindAllForAdminByUserRequestSchema;
  static response: OrdersAllowedStoresFindAllForAdminByUserResponseSchema;

  static role = RoleType.admin;
}
