import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersAllowedStoresFindMyRequestSchema } from './request';
import { OrdersAllowedStoresFindMyResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersAllowedStoresFindMyRequestSchema extends ContractData {
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersAllowedStoresFindMyResponseSchema extends ContractData {
*     stores: Store[];
* }
*
* export interface Store {
*     id:              number;
*     source:          Source;
*     storeId:         number;
*     storeName:       string;
*     userId:          string;
*     ordersTotal:     number;
*     paidOrdersTotal: number;
* }
*
* export type Source = "etsy" | "shipstation" | "manual";
*
* ```
*/
export class OrdersAllowedStoresFindMyRouteRpc extends BaseRouteRpc {
  static method = 'orders-allowed-stores-find-my';

  static request: OrdersAllowedStoresFindMyRequestSchema;
  static response: OrdersAllowedStoresFindMyResponseSchema;

  static role = RoleType.unverified;
}
