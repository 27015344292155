import { BaseRouteRpc, RoleType } from '../../../../../core';
import { OrdersLoadOrderLoadOrdersFromIdsRequestSchema } from './request';
import { OrdersLoadOrderLoadOrdersFromIdsResponseSchema } from './response';
  
/**
* @description
*
*
* Request interface:
* ```
* export interface OrdersLoadOrderLoadOrdersFromIdsRequestSchema extends ContractData {
*     data: Datum[];
* }
*
* export interface Datum {
*     externalNumber?: string;
*     storeId?:        number;
*     shipstationId?:  string;
* }
*
* ```
* Response interface:
*
* ```
* export interface OrdersLoadOrderLoadOrdersFromIdsResponseSchema extends ContractData {
*     report: Report[];
* }
*
* export interface Report {
*     order:   Order;
*     reason?: string;
*     status:  Status;
* }
*
* export interface Order {
*     externalNumber?: string;
*     storeId?:        number;
*     externalId?:     string;
* }
*
* export type Status = "success" | "failed" | "ignored";
*
* ```
*/
export class OrdersLoadOrderLoadOrdersFromIdsRouteRpc extends BaseRouteRpc {
  static method = 'orders-load-order-load-orders-from-ids';

  static request: OrdersLoadOrderLoadOrdersFromIdsRequestSchema;
  static response: OrdersLoadOrderLoadOrdersFromIdsResponseSchema;

  static role = RoleType.admin;
}
