import { default as admin_45products_45data_45tableqO3FBSc7JIMeta } from "/app/apps/nuxt-front/pages/admin/products/components/admin-products-data-table.vue?macro=true";
import { default as admin_45product_45detailhIjNeGLsu8Meta } from "/app/apps/nuxt-front/pages/admin/products/components/detail-view/admin-product-detail.vue?macro=true";
import { default as admin_45product_45detail_45picturesjZgTHNc4CiMeta } from "/app/apps/nuxt-front/pages/admin/products/components/detail-view/pictures/admin-product-detail-pictures.vue?macro=true";
import { default as admin_45product_45detail_45variantyZpWZxAS9IMeta } from "/app/apps/nuxt-front/pages/admin/products/components/detail-view/variants/admin-product-detail-variant.vue?macro=true";
import { default as admin_45product_45detail_45variantsWiWfCKF6h5Meta } from "/app/apps/nuxt-front/pages/admin/products/components/detail-view/variants/admin-product-detail-variants.vue?macro=true";
import { default as admin_45product_45detail_45variant_45rulesyUMz0LoSCAMeta } from "/app/apps/nuxt-front/pages/admin/products/components/detail-view/variants/rules/admin-product-detail-variant-rules.vue?macro=true";
import { default as use_45admin_45categories_45api6J4KzX6DBWMeta } from "/app/apps/nuxt-front/pages/admin/products/composables/use-admin-categories-api.ts?macro=true";
import { default as use_45admin_45delivery_45restrictions_45apiDR8bQ2TSmmMeta } from "/app/apps/nuxt-front/pages/admin/products/composables/use-admin-delivery-restrictions-api.ts?macro=true";
import { default as use_45admin_45product_45picture_45uploadXxDj8fINHuMeta } from "/app/apps/nuxt-front/pages/admin/products/composables/use-admin-product-picture-upload.ts?macro=true";
import { default as use_45admin_45products_45api9jj7Qt4iGfMeta } from "/app/apps/nuxt-front/pages/admin/products/composables/use-admin-products-api.ts?macro=true";
import { default as use_45product_45admin_45variants_45validationLgLcWaq5VFMeta } from "/app/apps/nuxt-front/pages/admin/products/composables/use-product-admin-variants-validation.ts?macro=true";
import { default as page3sshPrkN1ZMeta } from "/app/apps/nuxt-front/pages/admin/products/page.vue?macro=true";
import { default as productyr5mgaks0UMeta } from "/app/apps/nuxt-front/pages/admin/products/types/product.ts?macro=true";
import { default as sku_45ruleIo1tlwWfqrMeta } from "/app/apps/nuxt-front/pages/admin/products/validation/sku-rule.ts?macro=true";
import { default as sku_45unique_45ruleF7EKBXBNHNMeta } from "/app/apps/nuxt-front/pages/admin/products/validation/sku-unique-rule.ts?macro=true";
import { default as admin_45users_45data_45tableQemeQYgrBMMeta } from "/app/apps/nuxt-front/pages/admin/users/components/admin-users-data-table.vue?macro=true";
import { default as detail_45client_45modal4P2eo81deXMeta } from "/app/apps/nuxt-front/pages/admin/users/components/detail-client-modal.vue?macro=true";
import { default as detail_45client_45storesPLjPJpJPzaMeta } from "/app/apps/nuxt-front/pages/admin/users/components/detail-client-stores.vue?macro=true";
import { default as detail_45client_45csv_45reviewzJWTyt3JZ0Meta } from "/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-csv-review.vue?macro=true";
import { default as detail_45client_45discountjDxZHFafZYMeta } from "/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-discount.vue?macro=true";
import { default as detail_45client_45discounts_45viewRQComAl8HYMeta } from "/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-discounts-view.vue?macro=true";
import { default as detail_45client_45discountsxOPS8Vg6ahMeta } from "/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-discounts.vue?macro=true";
import { default as role_45statusRKu3EbegaHMeta } from "/app/apps/nuxt-front/pages/admin/users/components/role-status.vue?macro=true";
import { default as use_45admin_45allowed_45stores_45by_45user_45apitFnSE5iqxSMeta } from "/app/apps/nuxt-front/pages/admin/users/composables/use-admin-allowed-stores-by-user-api.ts?macro=true";
import { default as use_45admin_45clients_45apihicdKGxzOlMeta } from "/app/apps/nuxt-front/pages/admin/users/composables/use-admin-clients-api.ts?macro=true";
import { default as use_45admin_45clients_45discounts_45apiTCS2RN095rMeta } from "/app/apps/nuxt-front/pages/admin/users/composables/use-admin-clients-discounts-api.ts?macro=true";
import { default as pagef0U6x3n9NPMeta } from "/app/apps/nuxt-front/pages/admin/users/page.vue?macro=true";
import { default as clientArmFtKYpoAMeta } from "/app/apps/nuxt-front/pages/admin/users/types/client.ts?macro=true";
import { default as add_45billing_45methodYYAHizhaxcMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/add-billing-method.vue?macro=true";
import { default as amex2q8MddrG6AMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/amex.vue?macro=true";
import { default as defaultXUDmPEq4wzMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/default.vue?macro=true";
import { default as mastercardkSNALiAfW6Meta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/mastercard.vue?macro=true";
import { default as visaoAfSdaT6v9Meta } from "/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/visa.vue?macro=true";
import { default as use_45add_45payment_45method_45validationmhMJqQzwusMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/composables/use-add-payment-method-validation.ts?macro=true";
import { default as use_45countries_45apil8nKrAGjW8Meta } from "/app/apps/nuxt-front/pages/billing/update-methods/composables/use-countries-api.ts?macro=true";
import { default as use_45payment_45methods_45apiWxRrL65VTfMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/composables/use-payment-methods-api.ts?macro=true";
import { default as use_45regions_45apiyFJOYY9v9bMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/composables/use-regions-api.ts?macro=true";
import { default as pagehsoGMhirNkMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/page.vue?macro=true";
import { default as dataYTG03AAtmPMeta } from "/app/apps/nuxt-front/pages/billing/update-methods/types/data.ts?macro=true";
import { default as login_45formXi9bEhlHiKMeta } from "/app/apps/nuxt-front/pages/login/components/login-form.vue?macro=true";
import { default as use_45login_45validationnF7LowXB7YMeta } from "/app/apps/nuxt-front/pages/login/composables/use-login-validation.ts?macro=true";
import { default as pagexTyZRA7Ex1Meta } from "/app/apps/nuxt-front/pages/login/page.vue?macro=true";
import { default as pageAlMIiG74AAMeta } from "/app/apps/nuxt-front/pages/not-found/page.vue?macro=true";
import { default as add_45order_45contentGs640l78JUMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order-content.vue?macro=true";
import { default as add_45order_45producttWhbPjNUzoMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order-product.vue?macro=true";
import { default as add_45order_45productsZ6vTlTgqiXMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order-products.vue?macro=true";
import { default as add_45order_45stepsvm8x3gHTodMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order-steps.vue?macro=true";
import { default as add_45orderJ0IIsrsOSvMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/add-order.vue?macro=true";
import { default as add_45order_45first_45stepmr0MWb2E33Meta } from "/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-first-step.vue?macro=true";
import { default as add_45order_45second_45stepN0POmKcOz0Meta } from "/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-second-step.vue?macro=true";
import { default as add_45order_45third_45stepdxRi5yTIKNMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-third-step.vue?macro=true";
import { default as index5GDZUU8dsFMeta } from "/app/apps/nuxt-front/pages/orders/components/add-order/types/index.ts?macro=true";
import { default as bulk_45dndsyg7mp2yzRMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-dnd.vue?macro=true";
import { default as bulk_45ordersGHM6u9keSqMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-orders.vue?macro=true";
import { default as bulk_45uploadKu8ldf5jesMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-upload.vue?macro=true";
import { default as fileliqoRPRoH3Meta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/file.vue?macro=true";
import { default as order_45designszBccjX4VvxMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/order-designs.vue?macro=true";
import { default as product_45variant1flZmd3CMzMeta } from "/app/apps/nuxt-front/pages/orders/components/bulk-upload/product-variant.vue?macro=true";
import { default as order_45files_45colhgwE21Iww0Meta } from "/app/apps/nuxt-front/pages/orders/components/columns/order-files-col.vue?macro=true";
import { default as store_45icon3rhmVypSg4Meta } from "/app/apps/nuxt-front/pages/orders/components/data-table/store-icon.vue?macro=true";
import { default as order_45detail_45address_45street3YftxFtIOTMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-address-street.vue?macro=true";
import { default as order_45detail_45view_45addresse58nmVic8mMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-view-address.vue?macro=true";
import { default as orders_45detail_45viewk1w2u0dkF4Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/orders-detail-view.vue?macro=true";
import { default as detail_45pricelzizwy7ZCWMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/price/detail-price.vue?macro=true";
import { default as detail_45add_45product_45cardpYRCWskJgeMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product-card.vue?macro=true";
import { default as detail_45add_45producte2pbZB88b8Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product.vue?macro=true";
import { default as detail_45product_45bodyMSN1ixS2N3Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-body.vue?macro=true";
import { default as detail_45product_45cardbdpj7ooIBFMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-card.vue?macro=true";
import { default as detail_45product_45headerVBSy6M2HZFMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-header.vue?macro=true";
import { default as detail_45product_45invalid_45bodykUn6K40wfnMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-invalid-body.vue?macro=true";
import { default as detail_45productsVlsFFQq8VSMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-products.vue?macro=true";
import { default as order_45detail_45product_45designazuQh1YO1OMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-design.vue?macro=true";
import { default as order_45detail_45product_45designsBdv3gnk0B8Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-designs.vue?macro=true";
import { default as order_45detail_45product_45rulesOx2xiGaCFpMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-rules.vue?macro=true";
import { default as order_45detail_45product_45upload_45fileHm7pi1qcB7Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-upload-file.vue?macro=true";
import { default as order_45detail_45product_45upload_45inscriptionnfMRqbVlrYMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-upload-inscription.vue?macro=true";
import { default as detail_45product_45upload_45errorZReoMiHR3bMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-error.vue?macro=true";
import { default as detail_45product_45upload_45file_45error1WcWixickFMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-file-error.vue?macro=true";
import { default as detail_45product_45upload_45idler8eorSNga9Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-idle.vue?macro=true";
import { default as detail_45product_45upload_45replace75aW8OuMFfMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-replace.vue?macro=true";
import { default as detail_45product_45upload_45uploadedFCWtTVmpGLMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-uploaded.vue?macro=true";
import { default as detail_45product_45upload_45uploadingMu8FwS6qS9Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-uploading.vue?macro=true";
import { default as order_45detail_45product_45upload_45text_45editBYoE9nlnD2Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-edit.vue?macro=true";
import { default as order_45detail_45product_45upload_45text_45filledbwtLoO8VCAMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-filled.vue?macro=true";
import { default as order_45detail_45product_45upload_45text_45idleTXfmvbMSnOMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-idle.vue?macro=true";
import { default as detail_45product_45personalization_45editUN9pYLD67lMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/detail-product-personalization-edit.vue?macro=true";
import { default as order_45detail_45product_45personalizationwYC5St9kRuMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/order-detail-product-personalization.vue?macro=true";
import { default as detail_45enter_45sku75EBqDtmtzMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/products/sku/detail-enter-sku.vue?macro=true";
import { default as store_45infosl1BzQNkN5Meta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/store/store-info.vue?macro=true";
import { default as order_45detail_45view_45tracking_45number_45blockjrQVmmLCqHMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number-block.vue?macro=true";
import { default as order_45detail_45view_45tracking_45numberoXeCxhdTauMeta } from "/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number.vue?macro=true";
import { default as orders_45data_45table8IDRYR79J6Meta } from "/app/apps/nuxt-front/pages/orders/components/orders-data-table.vue?macro=true";
import { default as orders_45filters5WOvmjAgF0Meta } from "/app/apps/nuxt-front/pages/orders/components/orders-filters.vue?macro=true";
import { default as orders_45status_45filterKotXkhCAPbMeta } from "/app/apps/nuxt-front/pages/orders/components/orders-status-filter.vue?macro=true";
import { default as store_45selectWZMeKKUFyGMeta } from "/app/apps/nuxt-front/pages/orders/components/stores/store-select.vue?macro=true";
import { default as sync_45orders_45popupEKrPJMeZpjMeta } from "/app/apps/nuxt-front/pages/orders/components/sync-orders/sync-orders-popup.vue?macro=true";
import { default as sync_45orders_45statuseUrx1SgVF1Meta } from "/app/apps/nuxt-front/pages/orders/components/sync-orders/sync-orders-status.vue?macro=true";
import { default as use_45add_45order_45popupdn8MMuLbGZMeta } from "/app/apps/nuxt-front/pages/orders/composables/add-order/use-add-order-popup.ts?macro=true";
import { default as use_45add_45order_45validationCs0uGseAUrMeta } from "/app/apps/nuxt-front/pages/orders/composables/add-order/use-add-order-validation.ts?macro=true";
import { default as use_45has_45any_45apiBpjSmOjdafMeta } from "/app/apps/nuxt-front/pages/orders/composables/apis/use-has-any-api.ts?macro=true";
import { default as use_45orders_45apiLezoAyQZNDMeta } from "/app/apps/nuxt-front/pages/orders/composables/apis/use-orders-api.ts?macro=true";
import { default as use_45design_45upload_45textq9lu7OuIjfMeta } from "/app/apps/nuxt-front/pages/orders/composables/design/use-design-upload-text.ts?macro=true";
import { default as use_45design_45uploadvUrwBb75xSMeta } from "/app/apps/nuxt-front/pages/orders/composables/design/use-design-upload.ts?macro=true";
import { default as use_45detail_45view_453dsnHZbBS5USWMeta } from "/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-3ds.ts?macro=true";
import { default as use_45detail_45view_45cancel_45orderPBqSFJQcPbMeta } from "/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-cancel-order.ts?macro=true";
import { default as use_45detail_45view_45do_45paymentWxk9khc51uMeta } from "/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-do-payment.ts?macro=true";
import { default as use_45detail_45view_45editing_45addressmFUZpcWH7GMeta } from "/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-editing-address.ts?macro=true";
import { default as use_45detail_45view_45enter_45skuR6j12CVyW4Meta } from "/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-enter-sku.ts?macro=true";
import { default as use_45detail_45view_45update_45shipping_45addresshf4uN6hngFMeta } from "/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-update-shipping-address.ts?macro=true";
import { default as use_45allowed_45stores_45apiR2DI1AOXS5Meta } from "/app/apps/nuxt-front/pages/orders/composables/stores/use-allowed-stores-api.ts?macro=true";
import { default as use_45allowed_45stores_45for_45filter_45apiWs9pAiCFFJMeta } from "/app/apps/nuxt-front/pages/orders/composables/stores/use-allowed-stores-for-filter-api.ts?macro=true";
import { default as use_45active_45payment_45method_45warn_45tooltipWYcBevaF9uMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-active-payment-method-warn-tooltip.ts?macro=true";
import { default as use_45order_45detail_45view_45pageOnEs0VWlNMMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-order-detail-view-page.ts?macro=true";
import { default as use_45orders_45paramssNHYEjTSLnMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-orders-params.ts?macro=true";
import { default as use_45product_45by_45sku_45for_45correctionwRLE7mpuqSMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-product-by-sku-for-correction.ts?macro=true";
import { default as use_45selected_45ordersoBYObP1S3eMeta } from "/app/apps/nuxt-front/pages/orders/composables/use-selected-orders.ts?macro=true";
import { default as is_45order_45product_45disabledNBaFVXeyvTMeta } from "/app/apps/nuxt-front/pages/orders/helpers/is-order-product-disabled.ts?macro=true";
import { default as is_45order_45shipping_45address_45editableKbf01pEu6cMeta } from "/app/apps/nuxt-front/pages/orders/helpers/is-order-shipping-address-editable.ts?macro=true";
import { default as orders_45connect_45etsyiSHHWGkYYMMeta } from "/app/apps/nuxt-front/pages/orders/no-source/orders-connect-etsy.vue?macro=true";
import { default as orders_45no_45sourcelenmajatbrMeta } from "/app/apps/nuxt-front/pages/orders/no-source/orders-no-source.vue?macro=true";
import { default as pageQgZ0rfUofaMeta } from "/app/apps/nuxt-front/pages/orders/page.vue?macro=true";
import { default as has_45active_45payment_45method7bfx4ZwawEMeta } from "/app/apps/nuxt-front/pages/orders/state/has-active-payment-method.ts?macro=true";
import { default as ordersnG8LMhzIw8Meta } from "/app/apps/nuxt-front/pages/orders/types/orders.ts?macro=true";
import { default as product_45breadcrumbstqdTDGvCumMeta } from "/app/apps/nuxt-front/pages/product/components/product-breadcrumbs.vue?macro=true";
import { default as product_45infoYGRuDB6nbEMeta } from "/app/apps/nuxt-front/pages/product/components/product-info.vue?macro=true";
import { default as product_45mockup_45stylesMR9E4SXeFGMeta } from "/app/apps/nuxt-front/pages/product/components/product-mockup-styles.vue?macro=true";
import { default as use_45product_45apitVDEMnKyncMeta } from "/app/apps/nuxt-front/pages/product/composables/use-product-api.ts?macro=true";
import { default as page414Pc5Ll8fMeta } from "/app/apps/nuxt-front/pages/product/page.vue?macro=true";
import { default as category_45link7lCkmiQWpGMeta } from "/app/apps/nuxt-front/pages/products/components/category-link.vue?macro=true";
import { default as use_45categories_45apiN20x3TPXxYMeta } from "/app/apps/nuxt-front/pages/products/composables/use-categories-api.ts?macro=true";
import { default as use_45products_45api9qJQhu7uaHMeta } from "/app/apps/nuxt-front/pages/products/composables/use-products-api.ts?macro=true";
import { default as pagehVwMlxwkU5Meta } from "/app/apps/nuxt-front/pages/products/page.vue?macro=true";
import { default as registration_45stageswilsaZHpksMeta } from "/app/apps/nuxt-front/pages/registration/components/registration-stages.vue?macro=true";
import { default as stage_45indicatoru84cGmhdv1Meta } from "/app/apps/nuxt-front/pages/registration/components/stage-indicator.vue?macro=true";
import { default as company_45registration_45stagejh3BHLIfWEMeta } from "/app/apps/nuxt-front/pages/registration/components/stages/company-registration-stage.vue?macro=true";
import { default as profile_45registration_45stagetbOrQPxHwcMeta } from "/app/apps/nuxt-front/pages/registration/components/stages/profile-registration-stage.vue?macro=true";
import { default as use_45registration_45validation2Y7RSqDSCcMeta } from "/app/apps/nuxt-front/pages/registration/composables/use-registration-validation.ts?macro=true";
import { default as page8F47zOZ4UVMeta } from "/app/apps/nuxt-front/pages/registration/page.vue?macro=true";
import { default as pageXPNSETk2bzMeta } from "/app/apps/nuxt-front/pages/reset-password/page.vue?macro=true";
import { default as reset_45password_45first_45stepouh63ulySGMeta } from "/app/apps/nuxt-front/pages/reset-password/steps/reset-password-first-step.vue?macro=true";
import { default as reset_45password_45second_45step8C7EhHqALtMeta } from "/app/apps/nuxt-front/pages/reset-password/steps/reset-password-second-step.vue?macro=true";
import { default as reset_45password_45third_45stepEQOEMLKXsjMeta } from "/app/apps/nuxt-front/pages/reset-password/steps/reset-password-third-step.vue?macro=true";
import { default as steps7OgqM1XM0oMeta } from "/app/apps/nuxt-front/pages/reset-password/types/steps.ts?macro=true";
import { default as use_45reset_45password_45validationDRLwYAzTSQMeta } from "/app/apps/nuxt-front/pages/reset-password/validation/use-reset-password-validation.ts?macro=true";
import { default as company_45section_45contentPUQw7IdVPpMeta } from "/app/apps/nuxt-front/pages/settings/profile/components/company-section-content.vue?macro=true";
import { default as email_45section_45contentvQH303bFGKMeta } from "/app/apps/nuxt-front/pages/settings/profile/components/email-section-content.vue?macro=true";
import { default as password_45section_45contentyeWQqoW0siMeta } from "/app/apps/nuxt-front/pages/settings/profile/components/password-section-content.vue?macro=true";
import { default as profile_45section_45contentPItp25OiSPMeta } from "/app/apps/nuxt-front/pages/settings/profile/components/profile-section-content.vue?macro=true";
import { default as verification_45blockaskEj4HhzuMeta } from "/app/apps/nuxt-front/pages/settings/profile/components/verification/verification-block.vue?macro=true";
import { default as use_45company_45settings6OWx17NyZHMeta } from "/app/apps/nuxt-front/pages/settings/profile/composables/use-company-settings.ts?macro=true";
import { default as use_45password_45settingsliJBhNM3KZMeta } from "/app/apps/nuxt-front/pages/settings/profile/composables/use-password-settings.ts?macro=true";
import { default as use_45profile_45email_45settings4CdoPVFZY4Meta } from "/app/apps/nuxt-front/pages/settings/profile/composables/use-profile-email-settings.ts?macro=true";
import { default as use_45profile_45settings_45validation41WQXMFwYmMeta } from "/app/apps/nuxt-front/pages/settings/profile/composables/use-profile-settings-validation.ts?macro=true";
import { default as user_45profile_45me_45apifBLvpkn08DMeta } from "/app/apps/nuxt-front/pages/settings/profile/composables/user-profile-me-api.ts?macro=true";
import { default as page1HAcAUeInUMeta } from "/app/apps/nuxt-front/pages/settings/profile/page.vue?macro=true";
import { default as data2OUymTSV7kMeta } from "/app/apps/nuxt-front/pages/settings/profile/types/data.ts?macro=true";
import { default as add_45store_45modalrJs8hHFrgNMeta } from "/app/apps/nuxt-front/pages/settings/store/components/add-store/add-store-modal.vue?macro=true";
import { default as connect_45shipstationuHd3StYHxtMeta } from "/app/apps/nuxt-front/pages/settings/store/components/add-store/connect-shipstation.vue?macro=true";
import { default as source_45cardLNsGS5JPY2Meta } from "/app/apps/nuxt-front/pages/settings/store/components/add-store/source-card.vue?macro=true";
import { default as store_45rowVxT4ugxRTWMeta } from "/app/apps/nuxt-front/pages/settings/store/components/store-row.vue?macro=true";
import { default as use_45etsy_45connectionTfMKAga0rCMeta } from "/app/apps/nuxt-front/pages/settings/store/composables/use-etsy-connection.ts?macro=true";
import { default as use_45etsy_45settings_45storesVg3DXgVCbKMeta } from "/app/apps/nuxt-front/pages/settings/store/composables/use-etsy-settings-stores.ts?macro=true";
import { default as use_45settings_45storesfV6MznIfRUMeta } from "/app/apps/nuxt-front/pages/settings/store/composables/use-settings-stores.ts?macro=true";
import { default as pageouhqzG2dJwMeta } from "/app/apps/nuxt-front/pages/settings/store/page.vue?macro=true";
import { default as pagebykSkQeViMMeta } from "/app/apps/nuxt-front/pages/terms-and-conditions/page.vue?macro=true";
export default [
  {
    name: "admin-products-components-admin-products-data-table",
    path: "/admin/products/components/admin-products-data-table",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/components/admin-products-data-table.vue")
  },
  {
    name: "admin-products-components-detail-view-admin-product-detail",
    path: "/admin/products/components/detail-view/admin-product-detail",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/components/detail-view/admin-product-detail.vue")
  },
  {
    name: "admin-products-components-detail-view-pictures-admin-product-detail-pictures",
    path: "/admin/products/components/detail-view/pictures/admin-product-detail-pictures",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/components/detail-view/pictures/admin-product-detail-pictures.vue")
  },
  {
    name: "admin-products-components-detail-view-variants-admin-product-detail-variant",
    path: "/admin/products/components/detail-view/variants/admin-product-detail-variant",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/components/detail-view/variants/admin-product-detail-variant.vue")
  },
  {
    name: "admin-products-components-detail-view-variants-admin-product-detail-variants",
    path: "/admin/products/components/detail-view/variants/admin-product-detail-variants",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/components/detail-view/variants/admin-product-detail-variants.vue")
  },
  {
    name: "admin-products-components-detail-view-variants-rules-admin-product-detail-variant-rules",
    path: "/admin/products/components/detail-view/variants/rules/admin-product-detail-variant-rules",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/components/detail-view/variants/rules/admin-product-detail-variant-rules.vue")
  },
  {
    name: "admin-products-composables-use-admin-categories-api",
    path: "/admin/products/composables/use-admin-categories-api",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/composables/use-admin-categories-api.ts")
  },
  {
    name: "admin-products-composables-use-admin-delivery-restrictions-api",
    path: "/admin/products/composables/use-admin-delivery-restrictions-api",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/composables/use-admin-delivery-restrictions-api.ts")
  },
  {
    name: "admin-products-composables-use-admin-product-picture-upload",
    path: "/admin/products/composables/use-admin-product-picture-upload",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/composables/use-admin-product-picture-upload.ts")
  },
  {
    name: "admin-products-composables-use-admin-products-api",
    path: "/admin/products/composables/use-admin-products-api",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/composables/use-admin-products-api.ts")
  },
  {
    name: "admin-products-composables-use-product-admin-variants-validation",
    path: "/admin/products/composables/use-product-admin-variants-validation",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/composables/use-product-admin-variants-validation.ts")
  },
  {
    name: "admin-products-page",
    path: "/admin/products/page",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/page.vue")
  },
  {
    name: "admin-products-types-product",
    path: "/admin/products/types/product",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/types/product.ts")
  },
  {
    name: "admin-products-validation-sku-rule",
    path: "/admin/products/validation/sku-rule",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/validation/sku-rule.ts")
  },
  {
    name: "admin-products-validation-sku-unique-rule",
    path: "/admin/products/validation/sku-unique-rule",
    component: () => import("/app/apps/nuxt-front/pages/admin/products/validation/sku-unique-rule.ts")
  },
  {
    name: "admin-users-components-admin-users-data-table",
    path: "/admin/users/components/admin-users-data-table",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/admin-users-data-table.vue")
  },
  {
    name: "admin-users-components-detail-client-modal",
    path: "/admin/users/components/detail-client-modal",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/detail-client-modal.vue")
  },
  {
    name: "admin-users-components-detail-client-stores",
    path: "/admin/users/components/detail-client-stores",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/detail-client-stores.vue")
  },
  {
    name: "admin-users-components-discounts-detail-client-csv-review",
    path: "/admin/users/components/discounts/detail-client-csv-review",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-csv-review.vue")
  },
  {
    name: "admin-users-components-discounts-detail-client-discount",
    path: "/admin/users/components/discounts/detail-client-discount",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-discount.vue")
  },
  {
    name: "admin-users-components-discounts-detail-client-discounts-view",
    path: "/admin/users/components/discounts/detail-client-discounts-view",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-discounts-view.vue")
  },
  {
    name: "admin-users-components-discounts-detail-client-discounts",
    path: "/admin/users/components/discounts/detail-client-discounts",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/discounts/detail-client-discounts.vue")
  },
  {
    name: "admin-users-components-role-status",
    path: "/admin/users/components/role-status",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/components/role-status.vue")
  },
  {
    name: "admin-users-composables-use-admin-allowed-stores-by-user-api",
    path: "/admin/users/composables/use-admin-allowed-stores-by-user-api",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/composables/use-admin-allowed-stores-by-user-api.ts")
  },
  {
    name: "admin-users-composables-use-admin-clients-api",
    path: "/admin/users/composables/use-admin-clients-api",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/composables/use-admin-clients-api.ts")
  },
  {
    name: "admin-users-composables-use-admin-clients-discounts-api",
    path: "/admin/users/composables/use-admin-clients-discounts-api",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/composables/use-admin-clients-discounts-api.ts")
  },
  {
    name: "admin-users-page",
    path: "/admin/users/page",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/page.vue")
  },
  {
    name: "admin-users-types-client",
    path: "/admin/users/types/client",
    component: () => import("/app/apps/nuxt-front/pages/admin/users/types/client.ts")
  },
  {
    name: "billing-update-methods-components-add-billing-method",
    path: "/billing/update-methods/components/add-billing-method",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/add-billing-method.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-amex",
    path: "/billing/update-methods/components/card-icons/amex",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/amex.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-default",
    path: "/billing/update-methods/components/card-icons/default",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/default.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-mastercard",
    path: "/billing/update-methods/components/card-icons/mastercard",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/mastercard.vue")
  },
  {
    name: "billing-update-methods-components-card-icons-visa",
    path: "/billing/update-methods/components/card-icons/visa",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/components/card-icons/visa.vue")
  },
  {
    name: "billing-update-methods-composables-use-add-payment-method-validation",
    path: "/billing/update-methods/composables/use-add-payment-method-validation",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/composables/use-add-payment-method-validation.ts")
  },
  {
    name: "billing-update-methods-composables-use-countries-api",
    path: "/billing/update-methods/composables/use-countries-api",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/composables/use-countries-api.ts")
  },
  {
    name: "billing-update-methods-composables-use-payment-methods-api",
    path: "/billing/update-methods/composables/use-payment-methods-api",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/composables/use-payment-methods-api.ts")
  },
  {
    name: "billing-update-methods-composables-use-regions-api",
    path: "/billing/update-methods/composables/use-regions-api",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/composables/use-regions-api.ts")
  },
  {
    name: "billing-update-methods-page",
    path: "/billing/update-methods/page",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/page.vue")
  },
  {
    name: "billing-update-methods-types-data",
    path: "/billing/update-methods/types/data",
    component: () => import("/app/apps/nuxt-front/pages/billing/update-methods/types/data.ts")
  },
  {
    name: "login-components-login-form",
    path: "/login/components/login-form",
    component: () => import("/app/apps/nuxt-front/pages/login/components/login-form.vue")
  },
  {
    name: "login-composables-use-login-validation",
    path: "/login/composables/use-login-validation",
    component: () => import("/app/apps/nuxt-front/pages/login/composables/use-login-validation.ts")
  },
  {
    name: "login-page",
    path: "/login/page",
    component: () => import("/app/apps/nuxt-front/pages/login/page.vue")
  },
  {
    name: "not-found-page",
    path: "/not-found/page",
    component: () => import("/app/apps/nuxt-front/pages/not-found/page.vue")
  },
  {
    name: "orders-components-add-order-add-order-content",
    path: "/orders/components/add-order/add-order-content",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order-content.vue")
  },
  {
    name: "orders-components-add-order-add-order-product",
    path: "/orders/components/add-order/add-order-product",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order-product.vue")
  },
  {
    name: "orders-components-add-order-add-order-products",
    path: "/orders/components/add-order/add-order-products",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order-products.vue")
  },
  {
    name: "orders-components-add-order-add-order-steps",
    path: "/orders/components/add-order/add-order-steps",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order-steps.vue")
  },
  {
    name: "orders-components-add-order-add-order",
    path: "/orders/components/add-order/add-order",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/add-order.vue")
  },
  {
    name: "orders-components-add-order-steps-add-order-first-step",
    path: "/orders/components/add-order/steps/add-order-first-step",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-first-step.vue")
  },
  {
    name: "orders-components-add-order-steps-add-order-second-step",
    path: "/orders/components/add-order/steps/add-order-second-step",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-second-step.vue")
  },
  {
    name: "orders-components-add-order-steps-add-order-third-step",
    path: "/orders/components/add-order/steps/add-order-third-step",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/steps/add-order-third-step.vue")
  },
  {
    name: "orders-components-add-order-types",
    path: "/orders/components/add-order/types",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/add-order/types/index.ts")
  },
  {
    name: "orders-components-bulk-upload-bulk-dnd",
    path: "/orders/components/bulk-upload/bulk-dnd",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-dnd.vue")
  },
  {
    name: "orders-components-bulk-upload-bulk-orders",
    path: "/orders/components/bulk-upload/bulk-orders",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-orders.vue")
  },
  {
    name: "orders-components-bulk-upload-bulk-upload",
    path: "/orders/components/bulk-upload/bulk-upload",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/bulk-upload.vue")
  },
  {
    name: "orders-components-bulk-upload-file",
    path: "/orders/components/bulk-upload/file",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/file.vue")
  },
  {
    name: "orders-components-bulk-upload-order-designs",
    path: "/orders/components/bulk-upload/order-designs",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/order-designs.vue")
  },
  {
    name: "orders-components-bulk-upload-product-variant",
    path: "/orders/components/bulk-upload/product-variant",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/bulk-upload/product-variant.vue")
  },
  {
    name: "orders-components-columns-order-files-col",
    path: "/orders/components/columns/order-files-col",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/columns/order-files-col.vue")
  },
  {
    name: "orders-components-data-table-store-icon",
    path: "/orders/components/data-table/store-icon",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/data-table/store-icon.vue")
  },
  {
    name: "orders-components-detail-view-address-order-detail-address-street",
    path: "/orders/components/detail-view/address/order-detail-address-street",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-address-street.vue")
  },
  {
    name: "orders-components-detail-view-address-order-detail-view-address",
    path: "/orders/components/detail-view/address/order-detail-view-address",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/address/order-detail-view-address.vue")
  },
  {
    name: "orders-components-detail-view-orders-detail-view",
    path: "/orders/components/detail-view/orders-detail-view",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/orders-detail-view.vue")
  },
  {
    name: "orders-components-detail-view-price-detail-price",
    path: "/orders/components/detail-view/price/detail-price",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/price/detail-price.vue")
  },
  {
    name: "orders-components-detail-view-products-add-product-detail-add-product-card",
    path: "/orders/components/detail-view/products/add-product/detail-add-product-card",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product-card.vue")
  },
  {
    name: "orders-components-detail-view-products-add-product-detail-add-product",
    path: "/orders/components/detail-view/products/add-product/detail-add-product",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/add-product/detail-add-product.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-body",
    path: "/orders/components/detail-view/products/card/detail-product-body",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-body.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-card",
    path: "/orders/components/detail-view/products/card/detail-product-card",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-card.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-header",
    path: "/orders/components/detail-view/products/card/detail-product-header",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-header.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-product-invalid-body",
    path: "/orders/components/detail-view/products/card/detail-product-invalid-body",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-product-invalid-body.vue")
  },
  {
    name: "orders-components-detail-view-products-card-detail-products",
    path: "/orders/components/detail-view/products/card/detail-products",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/card/detail-products.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-design",
    path: "/orders/components/detail-view/products/file/order-detail-product-design",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-design.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-designs",
    path: "/orders/components/detail-view/products/file/order-detail-product-designs",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-designs.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-rules",
    path: "/orders/components/detail-view/products/file/order-detail-product-rules",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-rules.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-upload-file",
    path: "/orders/components/detail-view/products/file/order-detail-product-upload-file",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-upload-file.vue")
  },
  {
    name: "orders-components-detail-view-products-file-order-detail-product-upload-inscription",
    path: "/orders/components/detail-view/products/file/order-detail-product-upload-inscription",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/order-detail-product-upload-inscription.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-file-detail-product-upload-error",
    path: "/orders/components/detail-view/products/file/upload-file/detail-product-upload-error",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-error.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-file-detail-product-upload-file-error",
    path: "/orders/components/detail-view/products/file/upload-file/detail-product-upload-file-error",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-file-error.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-file-detail-product-upload-idle",
    path: "/orders/components/detail-view/products/file/upload-file/detail-product-upload-idle",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-idle.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-file-detail-product-upload-replace",
    path: "/orders/components/detail-view/products/file/upload-file/detail-product-upload-replace",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-replace.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-file-detail-product-upload-uploaded",
    path: "/orders/components/detail-view/products/file/upload-file/detail-product-upload-uploaded",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-uploaded.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-file-detail-product-upload-uploading",
    path: "/orders/components/detail-view/products/file/upload-file/detail-product-upload-uploading",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-file/detail-product-upload-uploading.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-text-order-detail-product-upload-text-edit",
    path: "/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-edit",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-edit.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-text-order-detail-product-upload-text-filled",
    path: "/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-filled",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-filled.vue")
  },
  {
    name: "orders-components-detail-view-products-file-upload-text-order-detail-product-upload-text-idle",
    path: "/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-idle",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/file/upload-text/order-detail-product-upload-text-idle.vue")
  },
  {
    name: "orders-components-detail-view-products-personalization-detail-product-personalization-edit",
    path: "/orders/components/detail-view/products/personalization/detail-product-personalization-edit",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/detail-product-personalization-edit.vue")
  },
  {
    name: "orders-components-detail-view-products-personalization-order-detail-product-personalization",
    path: "/orders/components/detail-view/products/personalization/order-detail-product-personalization",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/personalization/order-detail-product-personalization.vue")
  },
  {
    name: "orders-components-detail-view-products-sku-detail-enter-sku",
    path: "/orders/components/detail-view/products/sku/detail-enter-sku",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/products/sku/detail-enter-sku.vue")
  },
  {
    name: "orders-components-detail-view-store-store-info",
    path: "/orders/components/detail-view/store/store-info",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/store/store-info.vue")
  },
  {
    name: "orders-components-detail-view-tracking-number-order-detail-view-tracking-number-block",
    path: "/orders/components/detail-view/tracking-number/order-detail-view-tracking-number-block",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number-block.vue")
  },
  {
    name: "orders-components-detail-view-tracking-number-order-detail-view-tracking-number",
    path: "/orders/components/detail-view/tracking-number/order-detail-view-tracking-number",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/detail-view/tracking-number/order-detail-view-tracking-number.vue")
  },
  {
    name: "orders-components-orders-data-table",
    path: "/orders/components/orders-data-table",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/orders-data-table.vue")
  },
  {
    name: "orders-components-orders-filters",
    path: "/orders/components/orders-filters",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/orders-filters.vue")
  },
  {
    name: "orders-components-orders-status-filter",
    path: "/orders/components/orders-status-filter",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/orders-status-filter.vue")
  },
  {
    name: "orders-components-stores-store-select",
    path: "/orders/components/stores/store-select",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/stores/store-select.vue")
  },
  {
    name: "orders-components-sync-orders-sync-orders-popup",
    path: "/orders/components/sync-orders/sync-orders-popup",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/sync-orders/sync-orders-popup.vue")
  },
  {
    name: "orders-components-sync-orders-sync-orders-status",
    path: "/orders/components/sync-orders/sync-orders-status",
    component: () => import("/app/apps/nuxt-front/pages/orders/components/sync-orders/sync-orders-status.vue")
  },
  {
    name: "orders-composables-add-order-use-add-order-popup",
    path: "/orders/composables/add-order/use-add-order-popup",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/add-order/use-add-order-popup.ts")
  },
  {
    name: "orders-composables-add-order-use-add-order-validation",
    path: "/orders/composables/add-order/use-add-order-validation",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/add-order/use-add-order-validation.ts")
  },
  {
    name: "orders-composables-apis-use-has-any-api",
    path: "/orders/composables/apis/use-has-any-api",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/apis/use-has-any-api.ts")
  },
  {
    name: "orders-composables-apis-use-orders-api",
    path: "/orders/composables/apis/use-orders-api",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/apis/use-orders-api.ts")
  },
  {
    name: "orders-composables-design-use-design-upload-text",
    path: "/orders/composables/design/use-design-upload-text",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/design/use-design-upload-text.ts")
  },
  {
    name: "orders-composables-design-use-design-upload",
    path: "/orders/composables/design/use-design-upload",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/design/use-design-upload.ts")
  },
  {
    name: "orders-composables-edit-order-use-detail-view-3ds",
    path: "/orders/composables/edit-order/use-detail-view-3ds",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-3ds.ts")
  },
  {
    name: "orders-composables-edit-order-use-detail-view-cancel-order",
    path: "/orders/composables/edit-order/use-detail-view-cancel-order",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-cancel-order.ts")
  },
  {
    name: "orders-composables-edit-order-use-detail-view-do-payment",
    path: "/orders/composables/edit-order/use-detail-view-do-payment",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-do-payment.ts")
  },
  {
    name: "orders-composables-edit-order-use-detail-view-editing-address",
    path: "/orders/composables/edit-order/use-detail-view-editing-address",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-editing-address.ts")
  },
  {
    name: "orders-composables-edit-order-use-detail-view-enter-sku",
    path: "/orders/composables/edit-order/use-detail-view-enter-sku",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-enter-sku.ts")
  },
  {
    name: "orders-composables-edit-order-use-detail-view-update-shipping-address",
    path: "/orders/composables/edit-order/use-detail-view-update-shipping-address",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/edit-order/use-detail-view-update-shipping-address.ts")
  },
  {
    name: "orders-composables-stores-use-allowed-stores-api",
    path: "/orders/composables/stores/use-allowed-stores-api",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/stores/use-allowed-stores-api.ts")
  },
  {
    name: "orders-composables-stores-use-allowed-stores-for-filter-api",
    path: "/orders/composables/stores/use-allowed-stores-for-filter-api",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/stores/use-allowed-stores-for-filter-api.ts")
  },
  {
    name: "orders-composables-use-active-payment-method-warn-tooltip",
    path: "/orders/composables/use-active-payment-method-warn-tooltip",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-active-payment-method-warn-tooltip.ts")
  },
  {
    name: "orders-composables-use-order-detail-view-page",
    path: "/orders/composables/use-order-detail-view-page",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-order-detail-view-page.ts")
  },
  {
    name: "orders-composables-use-orders-params",
    path: "/orders/composables/use-orders-params",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-orders-params.ts")
  },
  {
    name: "orders-composables-use-product-by-sku-for-correction",
    path: "/orders/composables/use-product-by-sku-for-correction",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-product-by-sku-for-correction.ts")
  },
  {
    name: "orders-composables-use-selected-orders",
    path: "/orders/composables/use-selected-orders",
    component: () => import("/app/apps/nuxt-front/pages/orders/composables/use-selected-orders.ts")
  },
  {
    name: "orders-helpers-is-order-product-disabled",
    path: "/orders/helpers/is-order-product-disabled",
    component: () => import("/app/apps/nuxt-front/pages/orders/helpers/is-order-product-disabled.ts")
  },
  {
    name: "orders-helpers-is-order-shipping-address-editable",
    path: "/orders/helpers/is-order-shipping-address-editable",
    component: () => import("/app/apps/nuxt-front/pages/orders/helpers/is-order-shipping-address-editable.ts")
  },
  {
    name: "orders-no-source-orders-connect-etsy",
    path: "/orders/no-source/orders-connect-etsy",
    component: () => import("/app/apps/nuxt-front/pages/orders/no-source/orders-connect-etsy.vue")
  },
  {
    name: "orders-no-source-orders-no-source",
    path: "/orders/no-source/orders-no-source",
    component: () => import("/app/apps/nuxt-front/pages/orders/no-source/orders-no-source.vue")
  },
  {
    name: "orders-page",
    path: "/orders/page",
    component: () => import("/app/apps/nuxt-front/pages/orders/page.vue")
  },
  {
    name: "orders-state-has-active-payment-method",
    path: "/orders/state/has-active-payment-method",
    component: () => import("/app/apps/nuxt-front/pages/orders/state/has-active-payment-method.ts")
  },
  {
    name: "orders-types-orders",
    path: "/orders/types/orders",
    component: () => import("/app/apps/nuxt-front/pages/orders/types/orders.ts")
  },
  {
    name: "product-components-product-breadcrumbs",
    path: "/product/components/product-breadcrumbs",
    component: () => import("/app/apps/nuxt-front/pages/product/components/product-breadcrumbs.vue")
  },
  {
    name: "product-components-product-info",
    path: "/product/components/product-info",
    component: () => import("/app/apps/nuxt-front/pages/product/components/product-info.vue")
  },
  {
    name: "product-components-product-mockup-styles",
    path: "/product/components/product-mockup-styles",
    component: () => import("/app/apps/nuxt-front/pages/product/components/product-mockup-styles.vue")
  },
  {
    name: "product-composables-use-product-api",
    path: "/product/composables/use-product-api",
    component: () => import("/app/apps/nuxt-front/pages/product/composables/use-product-api.ts")
  },
  {
    name: "product-page",
    path: "/product/page",
    component: () => import("/app/apps/nuxt-front/pages/product/page.vue")
  },
  {
    name: "products-components-category-link",
    path: "/products/components/category-link",
    component: () => import("/app/apps/nuxt-front/pages/products/components/category-link.vue")
  },
  {
    name: "products-composables-use-categories-api",
    path: "/products/composables/use-categories-api",
    component: () => import("/app/apps/nuxt-front/pages/products/composables/use-categories-api.ts")
  },
  {
    name: "products-composables-use-products-api",
    path: "/products/composables/use-products-api",
    component: () => import("/app/apps/nuxt-front/pages/products/composables/use-products-api.ts")
  },
  {
    name: "products-page",
    path: "/products/page",
    component: () => import("/app/apps/nuxt-front/pages/products/page.vue")
  },
  {
    name: "registration-components-registration-stages",
    path: "/registration/components/registration-stages",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/registration-stages.vue")
  },
  {
    name: "registration-components-stage-indicator",
    path: "/registration/components/stage-indicator",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/stage-indicator.vue")
  },
  {
    name: "registration-components-stages-company-registration-stage",
    path: "/registration/components/stages/company-registration-stage",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/stages/company-registration-stage.vue")
  },
  {
    name: "registration-components-stages-profile-registration-stage",
    path: "/registration/components/stages/profile-registration-stage",
    component: () => import("/app/apps/nuxt-front/pages/registration/components/stages/profile-registration-stage.vue")
  },
  {
    name: "registration-composables-use-registration-validation",
    path: "/registration/composables/use-registration-validation",
    component: () => import("/app/apps/nuxt-front/pages/registration/composables/use-registration-validation.ts")
  },
  {
    name: "registration-page",
    path: "/registration/page",
    component: () => import("/app/apps/nuxt-front/pages/registration/page.vue")
  },
  {
    name: "reset-password-page",
    path: "/reset-password/page",
    component: () => import("/app/apps/nuxt-front/pages/reset-password/page.vue")
  },
  {
    name: "reset-password-steps-reset-password-first-step",
    path: "/reset-password/steps/reset-password-first-step",
    component: () => import("/app/apps/nuxt-front/pages/reset-password/steps/reset-password-first-step.vue")
  },
  {
    name: "reset-password-steps-reset-password-second-step",
    path: "/reset-password/steps/reset-password-second-step",
    component: () => import("/app/apps/nuxt-front/pages/reset-password/steps/reset-password-second-step.vue")
  },
  {
    name: "reset-password-steps-reset-password-third-step",
    path: "/reset-password/steps/reset-password-third-step",
    component: () => import("/app/apps/nuxt-front/pages/reset-password/steps/reset-password-third-step.vue")
  },
  {
    name: "reset-password-types-steps",
    path: "/reset-password/types/steps",
    component: () => import("/app/apps/nuxt-front/pages/reset-password/types/steps.ts")
  },
  {
    name: "reset-password-validation-use-reset-password-validation",
    path: "/reset-password/validation/use-reset-password-validation",
    component: () => import("/app/apps/nuxt-front/pages/reset-password/validation/use-reset-password-validation.ts")
  },
  {
    name: "settings-profile-components-company-section-content",
    path: "/settings/profile/components/company-section-content",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/components/company-section-content.vue")
  },
  {
    name: "settings-profile-components-email-section-content",
    path: "/settings/profile/components/email-section-content",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/components/email-section-content.vue")
  },
  {
    name: "settings-profile-components-password-section-content",
    path: "/settings/profile/components/password-section-content",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/components/password-section-content.vue")
  },
  {
    name: "settings-profile-components-profile-section-content",
    path: "/settings/profile/components/profile-section-content",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/components/profile-section-content.vue")
  },
  {
    name: "settings-profile-components-verification-verification-block",
    path: "/settings/profile/components/verification/verification-block",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/components/verification/verification-block.vue")
  },
  {
    name: "settings-profile-composables-use-company-settings",
    path: "/settings/profile/composables/use-company-settings",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/composables/use-company-settings.ts")
  },
  {
    name: "settings-profile-composables-use-password-settings",
    path: "/settings/profile/composables/use-password-settings",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/composables/use-password-settings.ts")
  },
  {
    name: "settings-profile-composables-use-profile-email-settings",
    path: "/settings/profile/composables/use-profile-email-settings",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/composables/use-profile-email-settings.ts")
  },
  {
    name: "settings-profile-composables-use-profile-settings-validation",
    path: "/settings/profile/composables/use-profile-settings-validation",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/composables/use-profile-settings-validation.ts")
  },
  {
    name: "settings-profile-composables-user-profile-me-api",
    path: "/settings/profile/composables/user-profile-me-api",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/composables/user-profile-me-api.ts")
  },
  {
    name: "settings-profile-page",
    path: "/settings/profile/page",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/page.vue")
  },
  {
    name: "settings-profile-types-data",
    path: "/settings/profile/types/data",
    component: () => import("/app/apps/nuxt-front/pages/settings/profile/types/data.ts")
  },
  {
    name: "settings-store-components-add-store-add-store-modal",
    path: "/settings/store/components/add-store/add-store-modal",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/components/add-store/add-store-modal.vue")
  },
  {
    name: "settings-store-components-add-store-connect-shipstation",
    path: "/settings/store/components/add-store/connect-shipstation",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/components/add-store/connect-shipstation.vue")
  },
  {
    name: "settings-store-components-add-store-source-card",
    path: "/settings/store/components/add-store/source-card",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/components/add-store/source-card.vue")
  },
  {
    name: "settings-store-components-store-row",
    path: "/settings/store/components/store-row",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/components/store-row.vue")
  },
  {
    name: "settings-store-composables-use-etsy-connection",
    path: "/settings/store/composables/use-etsy-connection",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/composables/use-etsy-connection.ts")
  },
  {
    name: "settings-store-composables-use-etsy-settings-stores",
    path: "/settings/store/composables/use-etsy-settings-stores",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/composables/use-etsy-settings-stores.ts")
  },
  {
    name: "settings-store-composables-use-settings-stores",
    path: "/settings/store/composables/use-settings-stores",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/composables/use-settings-stores.ts")
  },
  {
    name: "settings-store-page",
    path: "/settings/store/page",
    component: () => import("/app/apps/nuxt-front/pages/settings/store/page.vue")
  },
  {
    name: "terms-and-conditions-page",
    path: "/terms-and-conditions/page",
    component: () => import("/app/apps/nuxt-front/pages/terms-and-conditions/page.vue")
  }
]