<script setup lang="ts">
import { type PropType, useAttrs } from 'vue';
import type { RouteLocationRaw } from 'vue-router';
import { computed, toRefs } from 'vue';
import LoadingIcon from '../icons/loading-icon.vue';

const props = defineProps({
  type: {
    type: String as PropType<'link' | 'button' | 'submit'>,
    default: 'button',
    validator(value: string, props) {
      return ['link', 'button', 'submit'].includes(value);
    },
  },
  to: {
    type: [String, Object] as PropType<RouteLocationRaw>,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: undefined,
  },
  prefetch: {
    type: Boolean,
    default: false,
  },
  isNegative: {
    type: Boolean,
    default: false,
  },
  variant: {
    type: String as PropType<'primary' | 'secondary' | 'transparent' | 'gray'>,
    default: 'primary',
    validator(value: string) {
      return ['primary', 'secondary', 'transparent', 'gray'].includes(value);
    },
  },
  size: {
    type: String as PropType<'sm' | 'md' | 'tiny'>,
    default: 'md',
    validator(value: string) {
      return ['sm', 'md', 'tiny'].includes(value);
    },
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'click', event: MouseEvent): void
}>();

const { variant, size, disabled, type, isNegative } = toRefs(props);

const styleClassesStrategies = {
  primary: 'bg-button-primary-bg-idle c-button-primary-text-idle border-button-primary-border-idle '
    + 'hover:bg-button-primary-bg-hover hover:c-button-primary-text-hover border-button-primary-border-hover '
    + 'active:bg-button-primary-bg-pressed active:c-button-primary-text-pressed  border-button-primary-border-pressed ',
  secondary: 'bg-button-secondary-bg-idle c-button-secondary-text-idle border-button-secondary-border-idle border '
    + 'hover:bg-button-secondary-bg-hover hover:c-button-secondary-text-hover border-button-secondary-border-hover border '
    + 'active:bg-button-secondary-bg-pressed active:c-button-secondary-text-pressed  border-button-secondary-border-pressed border ',
  transparent: 'bg-button-transparent-bg-idle c-button-transparent-text-idle border-button-transparent-border-idle '
    + 'hover:bg-button-transparent-bg-hover hover:c-button-transparent-text-hover hover:border-button-transparent-border-hover '
    + 'active:bg-button-transparent-bg-pressed active:c-button-transparent-text-pressed  active:border-button-transparent-border-pressed ',
  gray: 'bg-button-gray-bg-idle c-button-gray-text-idle border-button-gray-border-idle '
    + 'hover:bg-button-gray-bg-hover hover:c-button-gray-text-hover border-button-gray-border-hover '
    + 'active:bg-button-gray-bg-pressed active:c-button-gray-text-pressed  border-button-gray-border-pressed ',
};

const negativeStyleClassesStrategies = {
  primary: 'bg-button-negative-primary-bg-idle c-button-negative-primary-text-idle border-button-negative-primary-border-idle '
    + 'hover:bg-button-negative-primary-bg-hover hover:c-button-negative-primary-text-hover border-button-negative-primary-border-hover '
    + 'active:bg-button-negative-primary-bg-pressed active:c-button-negative-primary-text-pressed  border-button-negative-primary-border-pressed ',
  secondary: 'bg-button-negative-secondary-bg-idle c-button-negative-secondary-text-idle border-button-negative-secondary-border-idle border '
    + 'hover:bg-button-negative-secondary-bg-hover hover:c-button-negative-secondary-text-hover border-button-negative-secondary-border-hover border '
    + 'active:bg-button-negative-secondary-bg-pressed active:c-button-negative-secondary-text-pressed  border-button-negative-secondary-border-pressed border ',
  transparent: 'bg-button-negative-transparent-bg-idle c-button-negative-transparent-text-idle border-button-negative-transparent-border-idle '
    + 'hover:bg-button-negative-transparent-bg-hover hover:c-button-negative-transparent-text-hover border-button-negative-transparent-border-hover '
    + 'active:bg-button-negative-transparent-bg-pressed active:c-button-negative-transparent-text-pressed  border-button-negative-transparent-border-pressed ',
  gray: 'bg-button-gray-bg-idle c-button-gray-text-idle border-button-gray-border-idle '
    + 'hover:bg-button-gray-bg-hover hover:c-button-gray-text-hover border-button-gray-border-hover '
    + 'active:bg-button-gray-bg-pressed active:c-button-gray-text-pressed  border-button-gray-border-pressed ',
};

const disabledStyleClasses = {
  primary: 'bg-button-primary-bg-disabled c-button-primary-text-disabled c-button-primary-border-disabled ',
  secondary: 'bg-button-secondary-bg-disabled c-button-secondary-text-disabled c-button-secondary-border-disabled border ',
  transparent: 'bg-button-secondary-bg-disabled c-button-secondary-text-disabled c-button-secondary-border-disabled ',
  gray: 'bg-button-secondary-bg-disabled c-button-secondary-text-disabled c-button-secondary-border-disabled border ',
};

const loadingStyleClasses = {
  primary: 'bg-button-primary-bg-pressed c-button-primary-text-pressed  border-button-primary-border-pressed ',
  secondary: 'bg-button-secondary-bg-pressed c-button-secondary-text-pressed  border-button-secondary-border-pressed border ',
  transparent: 'bg-button-transparent-bg-pressed c-button-transparent-text-pressed  border-button-transparent-border-pressed ',
  gray: 'bg-button-gray-bg-pressed c-button-gray-text-pressed  border-button-gray-border-pressed ',
};

const loadingNegativeStyleClasses = {
  primary: 'bg-button-negative-primary-bg-pressed c-button-negative-primary-text-pressed  border-button-negative-primary-border-pressed ',
  secondary: 'bg-button-negative-secondary-bg-pressed c-button-negative-secondary-text-pressed  border-button-negative-secondary-border-pressed border ',
  transparent: 'bg-button-negative-transparent-bg-pressed c-button-negative-transparent-text-pressed  border-button-negative-transparent-border-pressed ',
  gray: 'bg-button-gray-bg-pressed c-button-gray-text-pressed  border-button-gray-border-pressed ',
};

const loadingColorStrategies = {
  primary: 'white',
  secondary: '#156A2B',
  transparent: '#2DAD4D',
  gray: '#929492',
};

const negativeLoadingColorStrategies = {
  primary: 'white',
  secondary: '#D51D1D',
  transparent: '#D51D1D',
  gray: '#929492',
};

const loadingColor = computed(() => {
  if (isNegative.value) {
    return negativeLoadingColorStrategies[variant.value];
  }

  return loadingColorStrategies[variant.value];
});

const styleClasses = computed(() => {
  if (props.loading) {
    if (isNegative.value) {
      return loadingNegativeStyleClasses[variant.value];
    }

    return loadingStyleClasses[variant.value];
  }

  if (disabled.value) {
    return disabledStyleClasses[variant.value];
  }

  if (isNegative.value) {
    return negativeStyleClassesStrategies[variant.value];
  }

  return styleClassesStrategies[variant.value];
});

const sizeClassesStrategies = {
  tiny: 'text-cta-tiny h-7 px-2',
  sm: 'text-cta-small h-8 py-1.25 px-2',
  md: 'text-cta py-2.5 px-5',
};

const sizeClasses = computed(() => {
  return `${sizeClassesStrategies[size.value]} ${props.fullWidth ? 'w-full' : 'w-max'}`;
});

const inheritAttrs = useAttrs();

const attrs = computed(() => {
  return {
    type: type.value !== 'link' ? type.value : undefined,
    to: props.to,
    prefetch: props.prefetch || undefined,
    class: [
      styleClasses.value,
      sizeClasses.value,
      'transition-all rounded-md flex items-center justify-center relative',
      inheritAttrs.class,
      props.loading && 'cursor-not-allowed!',
    ].join(' '),
    disabled: disabled.value || undefined,
  };
});

function emitClick(event: MouseEvent) {
  if (!props.loading && !disabled.value) {
    emit('click', event);
  }
}
</script>

<template>
  <Component :is="type === 'link' ? 'NuxtLink' : 'button'" v-bind="attrs" @click="emitClick">
    <div :class="[loading && 'opacity-0']">
      <slot>
        {{ label }}
      </slot>
    </div>

    <Transition enter-from-class="opacity-0" leave-to-class="opacity-0">
      <div v-if="loading" class="absolute w-full h-full top-0 left-0 flex items-center justify-center">
        <LoadingIcon :color="loadingColor" />
      </div>
    </Transition>
  </Component>
</template>
