import revive_payload_client_18tZ8U9i2D from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VBBn6lUCRk from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UsZpaBnXXm from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_oooRZpaq1v from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_BDe8PBNVN6 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Cyp9TbMzkK from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_wZTD8PxbF1 from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8iVHdznXQM from "/app/apps/nuxt-front/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.31.0_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/apps/nuxt-front/.nuxt/components.plugin.mjs";
import prefetch_client_vZdEveMdtK from "/app/apps/nuxt-front/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qtw74gm2ijwwozq5o346tiayoq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/app/apps/nuxt-front/.nuxt/primevue-plugin.mjs";
import plugin_client_OQv4RUgNVL from "/app/apps/nuxt-front/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_@babel+parser@7.26.5_magicast@0.3.5_rollup@4.31.0_vue@3.5.13_typescript@5.7.3_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/app/apps/nuxt-front/.nuxt/unocss.mjs";
import maska_UHaKf2z1iQ from "/app/apps/nuxt-front/plugins/maska.ts";
import google_recaptcha_rYK9Kziv68 from "/app/apps/nuxt-front/plugins/google-recaptcha.ts";
export default [
  revive_payload_client_18tZ8U9i2D,
  unhead_VBBn6lUCRk,
  router_UsZpaBnXXm,
  payload_client_oooRZpaq1v,
  navigation_repaint_client_BDe8PBNVN6,
  check_outdated_build_client_Cyp9TbMzkK,
  chunk_reload_client_wZTD8PxbF1,
  plugin_vue3_8iVHdznXQM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_vZdEveMdtK,
  primevue_plugin_egKpok8Auk,
  plugin_client_OQv4RUgNVL,
  unocss_MzCDxu9LMj,
  maska_UHaKf2z1iQ,
  google_recaptcha_rYK9Kziv68
]